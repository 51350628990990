







import {Component, Prop, Vue} from "vue-property-decorator";
import {captureException} from "@sentry/browser";
import axios from "axios";


@Component({
  components: {},
  props: ["download", "type"],
})
export default class Download extends Vue {
  @Prop()
  public download!: any;
  @Prop()
  public type!: string;

  public downloadLink() {
    const newWindow = window.open();
    axios.get(`/downloads/${this.type}/token`, {withCredentials: true}).then(() => {
      newWindow.location.href = axios.defaults.baseURL + `/downloads/${this.type}/` + this.download.id;
    }).catch((err) => {
      captureException(err);
      newWindow.close();
    });
  }
}
