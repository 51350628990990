






import {Component, Vue} from "vue-property-decorator";
import Download from "./Download.vue";

@Component({
  components: {
    Download,
  },
  props: ["downloads", "type"],
})
export default class DownloadList extends Vue {
}
